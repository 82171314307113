<template>
  <div class="page">
    <img class="background-top__size" src="@/assets/imgs/calc/index-top-background.png" >
    <div class="content-page">
      <div class="center-box">
        <slot></slot>
        <div class="feedback" @click="$router.push({name: 'FeedbackPage'})">意见反馈</div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "CalcResultPage"
  }
</script>

<style scoped>
.background-top__size {
  width: 100%;
}

.content-page {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 16px;
  margin-top: -180px;
}

.center-box {
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 12px 90px 12px 12px;
  margin-bottom: 80px;
  padding: 30px 12px 20px 12px;
}

.feedback {
  color: #F98C39;
  font-size: 12px;
  font-weight: 700;
  height: 20px;
  line-height: 20px;
  text-align: center;
  width: 66px;
  margin: 10px auto;
  border-bottom: 1px solid #F98C39;
}
</style>