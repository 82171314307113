<template>
  <calc-result-page>
    <template v-if="res.pay">
    <div class="res-box">
      <div>补缴年限</div>
      <div class="res">{{ res.repayYear }}</div>
    </div>

    <div class="level-box">
      <div class="pay">一档补缴金额：{{ res.levelOneRepayFee }}元</div>
      <div>一档标准：{{ res.levelOne }}元</div>
    </div>

    <div class="level-box">
      <div class="pay">二档补缴金额：{{ res.levelTwoRepayFee }}元</div>
      <div>二档标准：{{ res.levelTwo }}元</div>
    </div>
    </template>
    <template v-else>
      <div class="success-box">
        <div class="success">恭喜您不用补缴！</div>
        <div>您已经超过了最低缴费年限10年，<br>不需要再补缴任何费用了。</div>
      </div>
    </template>

    <div class="tip">以上结果只是测算，最终以医保局计算结果为准</div>
  </calc-result-page>
</template>

<script>
import CalcResultPage from "@/components/CalcResultPage";
export default {
  components: {
    CalcResultPage,
  },
  mounted() {
    const res = this.$route.query.res
    if (res !== undefined) {
      this.res = JSON.parse(this.$route.query.res);
    }
  },
  data() {
    return {
      res: {},
    };
  },
};
</script>

<style scoped>
.res-box {
  margin-left: 20px;
  color: #4d4d4d;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 50px;
}

.res {
  font-size: 34px;
  color: #658CF1;
}

.level-box {
  position: relative;
  margin-left: 30px;
  font-size: 14px;
  color: #999999;
  margin-bottom: 12px;
}

.level-box::before {
  position: absolute;
  content: "";
  background-color: #658CF1;
  width: 3px;
  height: 80%;
  left: -10px;
  top: 4px;
}

.pay {
  font-size: 20px;
  font-weight: bold;
  color: #676767;
}

.tip {
  margin-top: 120px;
  margin-left: 40px;
  font-size: 12px;
  color: #658CF1;
}

.success-box {
  font-size: 16px;
  color: #999999;
  margin-left: 20px;
}

.success {
  font-size: 34px;
  color: #658CF1;
  font-weight: bold;
}
</style>